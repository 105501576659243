import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { useRef } from 'react';

import { WhatYouGetCard } from './WhatYouGetCard';
import { WhatYouGetCardContent } from './constants';

import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled(SwiperSlide)`
  padding-top: 32px;

  .swiper-slide {
    width: fit-content;
    height: auto;
  }
`;

type BenefitsCarouselProps = {
  cards: WhatYouGetCardContent[];
};

export const WhatYouGetCarousel = ({ cards }: BenefitsCarouselProps) => {
  const { track } = useCustomMixpanel();
  const swiperRef = useRef<SwiperClass | undefined>();

  const handleStateChange = (swiper: SwiperClass) => {
    swiperRef.current = swiper;
  };

  const handleTouchMove = () => {
    if (swiperRef.current?.touches.diff) {
      track(MixpanelEvents.CAROUSEL_TOUCH_SWIPE, {
        carouselId: 'get-an-emergency-fix-for',
        touchDirection: swiperRef.current?.touches.diff < 0 ? 'forward' : 'backward',
      });
    }
  };

  return (
    <Root>
      <Container>
        <Swiper
          slidesPerView="auto"
          spaceBetween={12}
          slidesOffsetAfter={12}
          slidesOffsetBefore={12}
          onSwiper={handleStateChange}
          onSlideChange={handleStateChange}
          onTouchEnd={handleTouchMove}
          speed={600}
        >
          {cards.map(({ heading, bulletPoints }) => (
            <SwiperSlide key={heading}>
              {({ isActive }) => (
                <WhatYouGetCard
                  key={heading}
                  heading={heading}
                  bulletPoints={bulletPoints}
                  isActive={isActive}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Root>
  );
};
