import { useEffect, useRef, useState } from 'react';

import { MixpanelEvents, ScrollDepthBreakpoint } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';

export const useTrackScrollDepth = () => {
  const scrollDepthRef = useRef(0);
  const { track } = useCustomMixpanel();
  const [scrollDepth, setScrollDepth] = useState(0);
  const [hitBreakpoints, setHitBreakpoints] = useState<ScrollDepthBreakpoint[]>([]);

  const handleScrollTracking = () => {
    const scrolledValue = window.scrollY;

    scrollDepthRef.current = Math.max(scrolledValue, scrollDepthRef.current);
    setScrollDepth((window.innerHeight + scrollDepthRef.current) / document.body.offsetHeight);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScrollTracking);
  }, []);

  useEffect(() => {
    if (
      scrollDepth > ScrollDepthBreakpoint.BREAKPOINT_25 &&
      scrollDepth < ScrollDepthBreakpoint.BREAKPOINT_50 &&
      !hitBreakpoints.includes(ScrollDepthBreakpoint.BREAKPOINT_25)
    ) {
      track(MixpanelEvents.USER_PAGE_SCROLL, { scrollDepth: '25%' });
      setHitBreakpoints((hitBreakpoints) => [
        ...hitBreakpoints,
        ScrollDepthBreakpoint.BREAKPOINT_25,
      ]);
    } else if (
      scrollDepth > ScrollDepthBreakpoint.BREAKPOINT_50 &&
      scrollDepth < ScrollDepthBreakpoint.BREAKPOINT_75 &&
      !hitBreakpoints.includes(ScrollDepthBreakpoint.BREAKPOINT_50)
    ) {
      track(MixpanelEvents.USER_PAGE_SCROLL, { scrollDepth: '50%' });
      setHitBreakpoints((hitBreakpoints) => [
        ...hitBreakpoints,
        ScrollDepthBreakpoint.BREAKPOINT_50,
      ]);
    } else if (
      scrollDepth > ScrollDepthBreakpoint.BREAKPOINT_75 &&
      !hitBreakpoints.includes(ScrollDepthBreakpoint.BREAKPOINT_75)
    ) {
      track(MixpanelEvents.USER_PAGE_SCROLL, { scrollDepth: '75%' });
      setHitBreakpoints((hitBreakpoints) => [
        ...hitBreakpoints,
        ScrollDepthBreakpoint.BREAKPOINT_75,
      ]);
    }
  }, [scrollDepth]);

  return scrollDepth;
};
