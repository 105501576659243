import { Card, Heading4, Margin } from '@ovotech/element';
import styled from 'styled-components';
import { useEffect } from 'react';

import { BulletWithIcon } from '@components/BulletWithIcon';
import { useCustomMixpanel } from '@services/Mixpanel';
import { MixpanelEvents } from '@entities/enums';

const RoundedCard = styled(Card)`
  position: relative;
  border-radius: 22px;
  ${({ theme }) => `
    padding-inline: ${theme.core.space[6]};
    padding-top: ${theme.core.space[8]};
  `};

  min-width: 268px;
  min-height: 236px;
`;

const BlackHeading4 = styled(Heading4)`
  ${({ theme }) => `
    color: ${theme.core.color.brand.black};
  `};
`;

const BulletPointContainer = styled.div`
  & > p {
    line-height: 1.2;
  }
`;

export const WhatYouGetCard = ({
  heading,
  bulletPoints,
  isActive,
}: {
  heading: string;
  bulletPoints: string[];
  isActive?: boolean;
}) => {
  const { track } = useCustomMixpanel();

  useEffect(() => {
    if (isActive) {
      track(MixpanelEvents.CAROUSEL_SLIDE_ACTIVE, {
        carouselId: 'get-an-emergency-fix-for',
        slideId: heading,
      });
    }
  }, [isActive]);

  return (
    <RoundedCard>
      <BlackHeading4>{heading}</BlackHeading4>
      <Margin top={8} />
      <BulletPointContainer id="bullet-container">
        {bulletPoints.map((bulletPoint, index) => (
          <BulletWithIcon
            flexStart
            key={index}
            icon={{ name: 'fill-green-tick', size: 16 }}
            text={bulletPoint}
          />
        ))}
      </BulletPointContainer>
    </RoundedCard>
  );
};
