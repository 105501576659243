import { WindowLocation } from '@reach/router';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import { navigate } from 'gatsby';

import { HomeRecoverPlanSection } from './components/HomeRecoverPlanSection';
import { WhatPeopleAreSayingSection } from './components/WhatPeopleAreSayingSection';
import WhatYouGet from './WhatYouGet/WhatYouGet';

import { BasicPage } from '@components/layout';
import { ModalContextProvider } from '@contexts/ModalContext';
import { Modal } from '@contexts/ModalContext/components';
import { HeroBanner } from '@src/modules/HomeRecoverVariation/HeroBanner';
import { FAQHomeRecover } from '@src/modules/HomeRecoverVariation/FAQHomeRecover';
import { useAuth } from '@hooks/useAuth';
import useQuote from '@hooks/useQuote';
import { CoverType, MixpanelEvents, ProductName } from '@entities/enums';
import { useAppContext } from '@contexts/AppContext';
import {
  boilerDetailsAtom,
  excessAtom,
  isAbsAtom,
  isAgentAtom,
  is95ExcessAtom,
  isHomeRecoverAtom,
  repairAtom,
  selectedCoverTypeAtom,
} from '@src/store/store';
import { LOGGED_IN_REPAIR_COST, LOGGED_OUT_REPAIR_COST } from '@constants/homeRepair';
import { ROUTE_HOME_RECOVER_REPAIR_DETAILS } from '@constants/routes';
import PromoService from '@services/PromoService';
import { getRedirectedExcessValue } from '@utils/excess';
import { DataLayer } from '@services/DataLayerService';
import { useCustomMixpanel } from '@services/Mixpanel';
import { HowItWorks } from '@src/modules/HomeRecoverVariation/HowItWorks';
import { useTrackScrollDepth } from '@hooks/useTrackScrollDepth';

type HomeRecoverVariationProps = {
  location: WindowLocation;
};

export const HomeRecoverVariation = ({ location }: HomeRecoverVariationProps) => {
  const { promo } = useAppContext();
  const [excess, setExcess] = useAtom(excessAtom);
  const boilerDetails = useAtomValue(boilerDetailsAtom);
  const [isHomeRecover, setIsHomeRecover] = useAtom(isHomeRecoverAtom);
  const setIs95Excess = useSetAtom(is95ExcessAtom);
  const setIsAbs = useSetAtom(isAbsAtom);
  const setIsAgent = useSetAtom(isAgentAtom);
  const setRepair = useSetAtom(repairAtom);
  const setSelectedCoverType = useSetAtom(selectedCoverTypeAtom);

  const { user, loading } = useAuth();

  const { track } = useCustomMixpanel();

  const { quotes } = useQuote({
    excess,
    promo,
    boilerAge: parseInt(boilerDetails.boilerAge),
    isHomeRecover,
  });

  const homeRecoverPlanRef = useRef<HTMLDivElement | null>(null);

  const scrollToHomeRecoverPlan = () => {
    track(MixpanelEvents.BUTTON_CLICK, {
      buttonId: 'heroAnchorCTA',
    });

    if (homeRecoverPlanRef.current) {
      homeRecoverPlanRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const quoteComplete = quotes.find((quote) => quote.coverType === CoverType.COMPLETE);

  const hasFreeRepairOffer = quoteComplete?.promotions.some((offer) => offer.type === 'FREEREPAIR');

  const isLoggedIn = Boolean(user?.accountId && !loading);

  const repairCost =
    isLoggedIn || hasFreeRepairOffer ? LOGGED_IN_REPAIR_COST : LOGGED_OUT_REPAIR_COST;

  useTrackScrollDepth();

  useEffect(() => {
    PromoService.clear();
  }, []);

  useEffect(() => {
    setIsHomeRecover(true);
    setIs95Excess(false);
    setIsAbs(false);
    setIsAgent(false);
    setRepair({
      cost: repairCost,
    });
    setExcess(getRedirectedExcessValue(excess));
  }, [repairCost]);

  useEffect(() => {
    track(MixpanelEvents.PAGE_HOME_RECOVER_OVERVIEW, { pageName: null });
  }, [track]);

  useEffect(() => {
    DataLayer.push([
      {
        event: 'selectedProductName',
        ProductName: ProductName.HOME_RECOVER,
      },
      {
        event: 'selectedCoverType',
        CoverType: CoverType.COMPLETE,
      },
    ]);
  }, []);

  const onContinue = useCallback(() => {
    track(MixpanelEvents.BUTTON_CLICK, {
      buttonId: 'book-emergency-repair',
    });

    setSelectedCoverType(quoteComplete?.coverType);

    navigate(ROUTE_HOME_RECOVER_REPAIR_DETAILS);
  }, [quoteComplete]);

  if (!quoteComplete) return null;

  return (
    <BasicPage
      pageName="home-recover"
      quote={quoteComplete}
      location={location}
      shouldIncludeFootnotes={true}
      isNested={true}
      showTopPageBanner={true}
    >
      <ModalContextProvider>
        <HeroBanner onScrollToPlan={scrollToHomeRecoverPlan} />
        <WhatYouGet />
        <HomeRecoverPlanSection
          isLoggedIn={isLoggedIn}
          repairCost={repairCost}
          onContinue={onContinue}
          quoteComplete={quoteComplete}
          ref={homeRecoverPlanRef}
        />
        <HowItWorks onContinue={onContinue} />
        <WhatPeopleAreSayingSection />
        <FAQHomeRecover />
        <Modal />
      </ModalContextProvider>
    </BasicPage>
  );
};
