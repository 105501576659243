import styled from 'styled-components';
import { Heading2, AccordionGroup, Accordion, P, TextLink } from '@ovotech/element';

import { mqMediumDown } from '@utils/mediaQuery';
import { MixpanelEvents } from '@entities/enums';
import { useCustomMixpanel } from '@services/Mixpanel';

const FAQHomeRecoverContainer = styled.div`
  padding: 168px 0 100px;

  ${mqMediumDown(`padding: 80px 0;`)};
`;

const StyledHeading = styled(Heading2)`
  ${({ theme }) => `
      color: ${theme.core.color.brand.ovo};
      font-size: 64px;
      text-align: center;
      padding: 0 ${theme.core.space[3]};
      margin-bottom: ${theme.core.space[12]};
      line-height: 0.8;
      letter-spacing: -2px;
      
      ${mqMediumDown(`font-size: 48px; margin-bottom: ${theme.core.space[10]}; text-align: left;`)};
      
      & + div {
        width: 820px;
        max-width: 90%;
        margin: 0 auto;
        
          & > div {
          background-color: ${theme.core.color.brand.white};
          
          & [class*="accordionTitle"] {
            color: ${theme.core.color.brand.midnight};
          }
          
          & [class*="contentWrapperExpanded"] {
            max-height: initial !important;
          }
          
          & [class*="contentExpanded"] p {
              margin-bottom: ${theme.core.space[3]};
          }
        }
      }
  `}
`;

const StyledList = styled.ul`
  ${({ theme }) => `
      margin-left: ${theme.core.space[4]};
      margin-bottom: ${theme.core.space[4]};
      list-style-type: initial;
  `}
`;

type FAQItem = {
  title: string;
  content: JSX.Element;
};

const faqData: FAQItem[] = [
  {
    title: 'How quickly can you send someone out?',
    content: (
      <>
        <P>
          If you take out Home Recover before 1pm, an engineer will aim to contact you the same day
          to schedule a repair. Otherwise they’ll usually be in touch within 24 hours.
        </P>
        <P>
          If there’s someone vulnerable in the property, we’ll prioritise your repair and get
          someone out as quickly as possible.
        </P>
      </>
    ),
  },
  {
    title: 'Will you replace my boiler if it can’t be repaired?',
    content: (
      <>
        <P>
          If your boiler is less than 7 years old at the initial policy start date and less than 10
          years old at renewal, we’ll pay up to £2,500 for the boiler replacement.
        </P>
        <P>
          If your boiler is 7 years old or more, but less than 10 years old at the initial policy
          start date and less than 10 years old at renewal, we’ll pay a contribution of £400 towards
          the boiler replacement.
        </P>
        <P>
          Check out our{' '}
          <TextLink
            aria-Label={`full terms and conditions`}
            href={
              'https://homeservices.ovoenergy.com/static/home-recover-terms-a5b50b3d5ba3e1853866e9d9a5c6676f.pdf'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            full terms and conditions for more info
          </TextLink>
          .
        </P>
      </>
    ),
  },
  {
    title: 'How does the excess work?',
    content: (
      <>
        <P>
          When you take out Home Recover, we ask you to choose an excess amount. It’s the amount
          you’ll pay every time we send an engineer out to you. It also affects how much your
          ongoing monthly cost is. You can pick an excess of either £0 or £60.
        </P>
        <P>
          If you choose £60, you’ll pay less each month. You’ll also pay £60 every time we send an
          engineer out. If the same problem happens again within 30 days, you won’t need to pay this
          excess twice.
        </P>
        <P>
          If you choose £0, you won’t pay anything when we send an engineer out. But your monthly
          cost will be more expensive. If you choose this option, there’s still a £50 excess on
          claims for external drains or taps and toilets.
        </P>
      </>
    ),
  },
  {
    title: 'Where can I read the terms and conditions?',
    content: (
      <>
        <P>
          It’s important to make sure Home Recover is right for you. These documents will help you
          understand exactly what is and isn’t covered.
        </P>
        <P>
          <TextLink
            aria-Label={`Insurance Product Information Document`}
            href={
              'https://homeservices.ovoenergy.com/static/ipid-complete-v4-324d72bd28ccbfc0d9f5f18ad4195c65.pdf'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Insurance Product Information Document
          </TextLink>
        </P>
        <P>
          <TextLink
            aria-Label={`Repair terms and conditions`}
            href={
              'https://homeservices.ovoenergy.com/static/home-recover-terms-a5b50b3d5ba3e1853866e9d9a5c6676f.pdf'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Repair terms and conditions
          </TextLink>
        </P>
        <P>
          <TextLink
            aria-Label={`Boiler cover terms and conditions`}
            href={
              'https://homeservices.ovoenergy.com/static/terms-v3-c1168b6524bdb7889d121e85346362cc.pdf'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Boiler cover terms and conditions
          </TextLink>
        </P>
      </>
    ),
  },
  {
    title: 'How much does a boiler repair cost?',
    content: (
      <>
        <P>
          Boiler repair is free for people who already get their energy from OVO. If you don&#39;t
          get your energy from OVO, the usual price is £79. However you can get a repair for free
          until 20 March 2025.
        </P>
      </>
    ),
  },
  {
    title: 'What kind of boiler repairs do you carry out?',
    content: (
      <>
        <P>
          There’s too many to mention here. You’ll find full details in{' '}
          <TextLink
            aria-Label={`Repair terms and conditions`}
            href={
              'https://homeservices.ovoenergy.com/static/home-recover-terms-a5b50b3d5ba3e1853866e9d9a5c6676f.pdf'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Repair terms and conditions
          </TextLink>{' '}
          on page 3.
        </P>
      </>
    ),
  },
  {
    title: 'Do you repair every type of boiler?',
    content: (
      <>
        <P>
          Although we don&#39;t cover all boilers, we do cover most models from the manufacturers
          listed:
        </P>
        <StyledList>
          <li>Alpha</li>
          <li>Ariston</li>
          <li>ATAG</li>
          <li>Baxi/ Potterton</li>
          <li>British Gas</li>
          <li>Glow worm</li>
          <li>Ideal</li>
          <li>Intergas</li>
          <li>Main</li>
          <li>Pro Combi</li>
          <li>Remeha</li>
          <li>Vailant</li>
          <li>Viessmann</li>
          <li>Vokera</li>
          <li>Worcester</li>
        </StyledList>
        <P>
          We don’t cover warm air central heating systems, commercial boilers or boilers that don’t
          operate on mains natural gas e.g. oil, LPG, electric.
        </P>
      </>
    ),
  },
  {
    title: 'What if I have more than one problem with my boiler?',
    content: (
      <>
        <P>
          We will repair the fault you reported. If you tell us you have no heating and hot water,
          we&#39;ll get that back up and running, but we won&#39;t fix other problems not related to
          this fault e.g. repair a leaking radiator.
        </P>
      </>
    ),
  },
  {
    title: 'What if I have more than one appliance/electrical problem that needs fixing?',
    content: (
      <>
        <P>
          Same as above, our expert engineers fix the first fault you report and then you’ll need to
          raise another claim to fix more issues. You can learn more about your cover by heading to
          page 3 in{' '}
          <TextLink
            aria-Label={`Repair terms and condition`}
            href={
              'https://homeservices.ovoenergy.com/static/home-recover-terms-a5b50b3d5ba3e1853866e9d9a5c6676f.pdf'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Repair terms and conditions
          </TextLink>
          .
        </P>
      </>
    ),
  },
  {
    title: 'How do I book an appointment?',
    content: (
      <>
        <P>
          Existing Home Recover customers can{' '}
          <TextLink
            aria-Label={`insurance claim online`}
            href={'https://claims.homeservices.ovoenergy.com/login'}
            target="_blank"
            rel="noopener noreferrer"
          >
            report an insurance claim online
          </TextLink>{' '}
          or call us on 0330 102 8905. You can also sign up to Home Recover to request a call from
          an engineer.
        </P>
      </>
    ),
  },
  {
    title: 'Will I be charged a call-out fee for every repair?',
    content: (
      <>
        <P>
          You’ll be charged £60 per callout after your first repair if you have chosen an excess
          when you bought cover. Even with £0 excess there is a £50 excess fee on claims for taps
          and toilets and external drainage.
        </P>
      </>
    ),
  },
  {
    title: 'Is the call-out fee charged when I arrange a boiler service?',
    content: (
      <>
        <P>No, your service is included in your cover.</P>
      </>
    ),
  },
  {
    title: 'Is having no heating considered to be an emergency?',
    content: (
      <>
        <P>
          No, emergencies are reserved for uncontrolled water leaks or full electrical power loss
          where someone relies on electricity to support medical equipment.
        </P>
      </>
    ),
  },
  {
    title: 'What is an emergency boiler repair?',
    content: (
      <>
        <P>It’s an uncontrolled water leak.</P>
      </>
    ),
  },
  {
    title: 'How long does emergency boiler repair take?',
    content: (
      <>
        <P>
          How long your repair takes depends on the problem. In the event of an emergency (an
          uncontrolled water leak), we aim to have someone contact you within two hours. For
          everything else, we’ll get back to you on the same day if you call before 1pm, or within
          24 hours if you call after. This doesn’t mean we’ll be able to fix the problem within that
          time.
        </P>
      </>
    ),
  },
  {
    title: 'What should you do when the boiler stops working?',
    content: (
      <>
        <P>
          You can{' '}
          <TextLink
            aria-Label={`book a repair online`}
            href={'https://ondemand.ovoenergy.com/service/boiler-service'}
            target="_blank"
            rel="noopener noreferrer"
          >
            book a repair online
          </TextLink>{' '}
          or call us on 0330 102 8905. Our{' '}
          <TextLink
            aria-Label={`help articles`}
            href={'https://www.ovoenergy.com/boiler-help'}
            target="_blank"
            rel="noopener noreferrer"
          >
            help articles
          </TextLink>{' '}
          may also help you solve your problem or give you the advice you need.
        </P>
      </>
    ),
  },
];

export const FAQHomeRecover = () => {
  const { track } = useCustomMixpanel();

  const handleAccordionClick = (title: string) => {
    track(MixpanelEvents.TOGGLE_FAQ_ACCORDION, {
      accordionId: title,
    });
  };

  return (
    <>
      <FAQHomeRecoverContainer>
        <StyledHeading>Frequently asked questions</StyledHeading>
        <AccordionGroup>
          {faqData.map(({ title, content }, index) => (
            <Accordion key={index} title={title} onClick={() => handleAccordionClick(title)}>
              {content}
            </Accordion>
          ))}
        </AccordionGroup>
      </FAQHomeRecoverContainer>
    </>
  );
};
